//TOP

$(function(){
  //読み込み時動作
  $(window).on('load',function() {
    startAnimetion();
  });

  //ドアアニメーション
  function startAnimetion() {
    const top = $('#top');
    top.addClass('is-animate');
  }

  //サービス紹介スライダー
  $('.p-service__list[data-js="slider"]').slick({
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 700,
        settings: {
          centerMode: true,
          centerPadding: '40px',
          infinite: true,
          autoplay: true,
          autoplaySpeed: 5000,
          speed: 300,
          slidesToShow: 1,
          arrows: false,
        }
      }
    ]
  });

  //強みスライダー
  $('.p-strength__list[data-js="slider"]').slick({
    centerMode: true,
    centerPadding: '0',
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 300,
    slidesToShow: 1,
    arrows: true,
    dots: true,
  });

});